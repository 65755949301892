import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout/layout";
import SEO from "../components/layout/seo";
import BasicLayoutStructure from "../components/BasicLayoutStructure";
import DocumentGroup from "../components/DocumentGroup";
import sortFunction from "../components/sort";

const Dokumentai = () => {
  const data = useStaticQuery(query);

  let documentGroups = data
    .allStrapiDokumentais
    .group
    .sort((a, b) => sortFunction(a.edges[0].node.dokumento_tipas.prioritetas, b.edges[0].node.dokumento_tipas.prioritetas))

  documentGroups.forEach(group => group.edges.sort((a,b) => sortFunction(a.node.prioritetas, b.node.prioritetas)));
    
  return <Layout>
    <SEO
      seo={{
        metaTitle: "gimnastika.lt | Dokumentai",
      }}
    />
    <BasicLayoutStructure header="Dokumentai" left={<>
      {documentGroups.map((group, i) =>
        <DocumentGroup key={i}
          name={group.edges[0].node.dokumento_tipas.pavadinimas}
          documents={group.edges.map(doc => doc.node)} />)}
    </>} />
  </Layout>
};

export default Dokumentai;

const query = graphql`
  query {
    allStrapiDokumentais(sort: {fields: dokumento_tipas___prioritetas}) {      
      group(field: dokumento_tipas___pavadinimas) {
        edges {
          node {
            id
            pavadinimas
            prioritetas
            failas {
              url
            }    
            dokumento_tipas {
              pavadinimas
              slug
              prioritetas
            }
          }
        }
      }
    }
  }
`